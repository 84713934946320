import { FC } from 'react';
import { Link } from 'react-router-dom';
const Footer: FC = () => {
    const handleHaibao =()=>{
        window.location.replace('https://www.haibaobaoxian.com/haibao')
    }
    return (
        <footer className="footer-container cor-aux-white">
            <section className="d-flex flex-jc-sb pt-48px m-a">
                <dl className="footer-info flex-1">
                    <dt />
                    <dd>电话：0571-87356973</dd>
                    <dd>邮箱：info@haibaobaoxian.com</dd>
                    <dd>
                        地址：浙江省杭州市余杭区高教路海外高层次人才创新园5幢707
                    </dd>
                </dl>
                <dl className="footer-about">
                    <dt>业务介绍</dt>
                    <dd> <span  className="no-link" onClick={()=>handleHaibao()}>海保</span></dd>
                    <dd><Link to="/jingsuanshi" className="no-link">晶算师</Link></dd>
                    <dd><Link to="/jsy" className="no-link">晶算云服</Link></dd>
                    {/* <dd>心橙保</dd> */}
                </dl>
                <dl className="footer-about">
                    <dt>关于大鱼</dt>
                    <dd><Link to="/introduction" className="no-link">关于我们</Link></dd>
                    <dd><Link to="/contact" className="no-link">联系我们</Link></dd>
                    <dd><Link to="/recruit" className="no-link">加入我们</Link></dd>
                </dl>
            </section>
            <p className="m-a ta-c"> <a href="https://beian.miit.gov.cn" className="no-link">ICP备案号：浙ICP备18049580号 </a></p>
        </footer>
    );
};

export default Footer;
