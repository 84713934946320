import React, { ReactElement, FC, FunctionComponent } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import routerConfigs, { Meta, RouterOption, Wrapper } from './routerConfigs';

function WrapRouteCmp(meta: Meta, Cmp: any): ReactElement<Meta> {
    if (meta.title) {
        document.title = meta.title;
    }

    return <Cmp meta={meta} />;
}

function NotFound() {
    return <Route render={() => <Redirect to="/" />} />;
}

// function renderRoute(routes: RouterOption[] | undefined, defaultAuth: boolean): RouterOption[];

function renderRoute(
    routes: Array<RouterOption | Wrapper> = [],
    defaultAuth: boolean
): any[] {
    return routes.map((it, idx) => {
        const {
            meta = {},
            path,
            component,
            children,
            wrapper,
            auth = defaultAuth,
            ...rest
        } = it as RouterOption;

        const key = String(rest.path) + idx;

        if (component) {
            return wrapper ? (
                React.createElement(
                    component as FunctionComponent,
                    { key },
                    <Switch>
                        {renderRoute(children, auth)}
                        <NotFound key={key} />
                    </Switch>
                )
            ) : (
                <Route
                    key={key}
                    path={path}
                    // meta={meta}
                    component={WrapRouteCmp.bind(null, meta, component, auth)}
                    // render={routeRenderFn.bind(this, meta, component)}
                    {...rest}
                />
            );
        }

        return Array.isArray(children) ? renderRoute(children, auth) : null;
    });
}

export default function Router() {
    const { routes, basename, auth } = routerConfigs;

    return (
        <BrowserRouter basename={basename}>
            <Switch>{renderRoute(routes, auth)}</Switch>
        </BrowserRouter>
    );
}
