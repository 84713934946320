// This must be the first line in src/index.js  ---- polyfill stable
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'minireset.css';
import 'animate.css';
import './assets/index.scss';
import Router from './router';
import Store from 'react-elf';
import reducers from './store/reducers';
// import "swiper/css/bundle";
ReactDOM.render(
    <>
        {/* store 需要放到 Router之前 */}
        <Store reducers={reducers} />
        <Router />
    </>,
    document.getElementById('root'));
