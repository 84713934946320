import { FC } from 'react';
import './index.scss';
import logoSrc from '../../../assets/icons/logo.png';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router';
import { formSerializeToObject } from '../../../utils';
const Layout: FC = ({ children }) => {
    const handleHref = (url) => {
        console.log(url);
        window.location.href = url;
    };
    const routeLocation = useLocation();

    const handleHaibao =()=>{
        window.location.replace('https://www.haibaobaoxian.com/haibao')
    }
    return (
        <header className="d-flex header-container flex-jc-sb ai-c">
            <div>
                <img src={logoSrc} alt="大鱼科技"  style={{width: '102px'}}/>
            </div>
            <ul className="d-if nav cor-aux">
                <li
                    onClick={() => handleHref('/')}
                    className={
                        `${routeLocation.pathname}` == '/' ? 'active-cls' : ''
                    }
                >
                    <span>首页</span>
                </li>
                <li className={`${routeLocation.pathname}` == '/haibao' || `${routeLocation.pathname}` ==  '/jingsuanshi'  || `${routeLocation.pathname}` ==  '/jsy' ? 'active-cls2' : ''}>
                    <span className="active-cls2-title">业务介绍</span>
                    <i className="arrow cor-aux-m ml-5px"/>
                    <ul className={`sub-menu`}>
                        <li
                            className={
                                `${routeLocation.pathname}` == '/haibao'
                                    ? 'active-cls-inner'
                                    : ''
                            }
                            onClick={()=>handleHaibao()}
                        >
                            <span >海保</span>
                            <span  className="stretch" >  </span>
                        </li>
                        <li
                            className={
                                `${routeLocation.pathname}` == '/jingsuanshi'
                                    ? 'active-cls-inner'
                                    : ''
                            }
                        >
                            <span>晶算师</span>
                            <Link to="/jingsuanshi" className="stretch" />
                        </li>
                        <li
                            className={
                                `${routeLocation.pathname}` == '/jsy'
                                    ? 'active-cls-inner'
                                    : ''
                            }
                        >
                            <span>晶算云服</span>
                            <Link to="/jsy" className="stretch" />
                        </li>
                        {/* <li>心橙保</li> */}
                    </ul>
                </li>
                <li
                    onClick={() => handleHref('/tec')}
                    className={
                        `${routeLocation.pathname}` == '/tec'
                            ? 'active-cls'
                            : ''
                    }
                >
                    {' '}
                    <span>技术优势</span>
                </li>
                <li
                    onClick={() => handleHref('/news')}
                    className={
                        `${routeLocation.pathname}` == '/news'
                            ? 'active-cls'
                            : ''
                    }
                >
                    {' '}
                    <span>新闻中心</span>
                </li>
                <li  className={`${routeLocation.pathname}` == '/introduction' || `${routeLocation.pathname}` ==  '/contact'  || `${routeLocation.pathname}` ==  '/recruit' ? 'active-cls2' : ''}>
                    <span className="active-cls2-title">关于大鱼 </span>
                    <i className="arrow cor-aux-m ml-5px" />
                    <ul className="sub-menu">
                        <li
                            className={
                                `${routeLocation.pathname}` == '/introduction'
                                    ? 'active-cls-inner'
                                    : ''
                            }
                        >
                            <span>公司介绍</span>
                            <Link to="/introduction" className="stretch" />
                        </li>
                        <li
                            className={
                                `${routeLocation.pathname}` == '/contact'
                                    ? 'active-cls-inner'
                                    : ''
                            }
                        >
                            <span>联系我们</span>
                            <Link to="/contact" className="stretch" />
                        </li>
                        <li
                            className={
                                `${routeLocation.pathname}` == '/recruit'
                                    ? 'active-cls-inner'
                                    : ''
                            }
                        >
                            <span>加入我们</span>
                            <Link to="/recruit" className="stretch" />
                        </li>
                    </ul>
                </li>
            </ul>
        </header>
    );
};

export default Layout;
