import { FunctionComponent, ReactElement } from 'react';
import loadable from '@loadable/component';
import Layout from '../pages/component/layout';
const dynamicCmp = (page: string) => loadable(() => import(`../pages/${page}`));

export interface Meta {
    title: string;
}

export interface RouterOption {
    path: string;
    component: FunctionComponent<any> | any;
    meta: Meta;
    exact?: boolean;
    auth?: boolean;
    [propsName: string]: any;
}

export interface Wrapper {
    wrapper: boolean;
    component: FunctionComponent<any> | Function;
    children: RouterOption[];
}

export interface RouterOptions {
    basename: string;
    auth: boolean;
    routes: Array<RouterOption | Wrapper>;
}
/**
 * 404、错误页、默认页等配置都可以在当前路由配置中设定；
 */

const routerOptions: RouterOptions = {
    basename: '/',
    // 默认 auth 配置，单个路由配置会覆盖该配置；
    auth: true,
    routes: [
        {
            wrapper: false,
            component: dynamicCmp('index'),
            path: '/',
            exact: true,
            auth: false,
            meta: {
                title: '大鱼科技'
            }
        },
        {
            wrapper: true,
            component: Layout,
            children: [
                // {
                // path: '/',
                // exact: true,
                // auth: false,
                // component: dynamicCmp('index'),
                // meta: {
                //     title: '大鱼科技'
                // }
                // },
                {
                    path: '/introduction',
                    exact: true,
                    auth: false,
                    component: dynamicCmp('introduction'),
                    meta: {
                        title: '公司介绍'
                    }
                },
                {
                    path: '/haibao',
                    exact: true,
                    auth: false,
                    component: dynamicCmp('haibao'),
                    meta: {
                        title: '海保'
                    }
                },
                {
                    path: '/jingsuanshi',
                    exact: true,
                    auth: false,
                    component: dynamicCmp('jss'),
                    meta: {
                        title: '晶算师'
                    }
                },
                {
                    path: '/jsy',
                    exact: true,
                    auth: false,
                    component: dynamicCmp('i'),
                    meta: {
                        title: '晶算云服'
                    }
                },
                {
                    path: '/tec',
                    exact: true,
                    auth: false,
                    component: dynamicCmp('tec'),
                    meta: {
                        title: '技术优势'
                    }
                },
                {
                    path: '/contact',
                    exact: true,
                    auth: false,
                    component: dynamicCmp('map'),
                    meta: {
                        title: '联系我们'
                    }
                },
                {
                    path: '/recruit',
                    exact: true,
                    auth: false,
                    component: dynamicCmp('recruit'),
                    meta: {
                        title: '加入我们'
                    }
                },
                {
                    path: '/news',
                    exact: true,
                    auth: false,
                    component: dynamicCmp('news'),
                    meta: {
                        title: '新闻中心'
                    }
                },
                {
                    path: '/newsDetail',
                    exact: true,
                    auth: false,
                    component: dynamicCmp('newsdetail'),
                    meta: {
                        title: '新闻详情'
                    }
                }
            ]
        }
    ]
};

export default routerOptions;
