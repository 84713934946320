
const init = {

};

function reducer(state, action) {
    switch (action.type) {
        case 'init':
            return init;

        default:
            return state;
    }
}

export default {
    reducer,
    name: 'global',
    init
};
