import { FC } from 'react';
import Header from './header';
import Footer from './footer';

const Layout: FC = ({ children }) => {
    return (
        <div className="h-100 w-100vw " id="outer" >
            <Header />
            {children}
            <Footer />
        </div>
    );
};

export default Layout;
